import EditCertificationMark from "../pages/certification-mark/EditCertificationMark"
import EditMember from "../pages/member/EditMember"
import LoginPage from "../pages/LoginPage"
import LinkPage from "../pages/error/LinkPage"

const { lazy, Suspense } = require("react")
const { createBrowserRouter } = require("react-router-dom")

const RegisterPage = lazy(() => import('../pages/RegisterPage'))
const MemberList = lazy(() => import("../pages/member/MemberList"))
const WithdrawMemberList = lazy(() => import("../pages/member/WithdrawMember"))
const MemberDetail = lazy(() => import("../pages/member/MemberDetail"))
const MemberStatsPage = lazy(() => import("../pages/member/StatsPage"))
const OnProgressedTradePermissionList = lazy(() => import("../pages/trade-permission/OnTradePermissionList"))
const TradePermissionList = lazy(() => import("../pages/trade-permission/TradePermissionList"))
const CertificationMarkList = lazy(() => import("../pages/certification-mark/CertificationMarkList"))
const CertificationMarkProgressedList = lazy(() => import("../pages/certification-mark/ProgressedCertificationMarkList"))
const CertificationMarkDetail = lazy(() => import("../pages/certification-mark/CertificationMarkDetail"))
const AdminListPage = lazy(() => import("../pages/admin/AdminListPage"))
const JusoPopup = lazy(() => import("../pages/address/JusoPopup"))
const TradeList = lazy(() => import("../pages/trade/TradeList"))
const TradeDetailPage = lazy(() => import("../pages/trade/TradeDetailPage"))
const RefundList = lazy(() => import("../pages/trade/RefundList"))
const RefundDetailPage = lazy(() => import("../pages/trade/RefundDetailPage"))
const TradeStatsPage = lazy(() => import("../pages/trade/StatsPage"))
const AuctionProgressedList = lazy(() => import("../pages/business/AuctionProgressedList"))
const AuctionsList = lazy(() => import("../pages/business/AuctionList"))
const AuctionDetailPage = lazy(() => import("../pages/business/AuctionDetailPage"))
const AuctionEditPage = lazy(() => import("../pages/business/EditAuction"))
const SaleProgressedList = lazy(() => import("../pages/business/SaleProgressedList"))
const SalesList = lazy(() => import("../pages/business/SaleList"))
const SaleDetailPage = lazy(() => import("../pages/business/SaleDetailPage"))
const SaleEditPage = lazy(() => import("../pages/business/EditSale"))
const ProductList = lazy(() => import("../pages/product/ProductList"))
const ProductDetailPage = lazy(() => import("../pages/product/ProductDetailPage"))
const ProductEditPage = lazy(() => import("../pages/product/EditProduct"))
const InquiryList = lazy(() => import("../pages/inquiry/InquiryList"))
const InquiryDetailPage = lazy(() => import("../pages/inquiry/InquiryDetailPage"))
const ReportList = lazy(() => import("../pages/report/ReportList"))
const ReportDetailPage = lazy(() => import("../pages/report/ReportDetailPage"))
const ErrorPage = lazy(() => import("../pages/error/ErrorPage"))
const LoadingPage = lazy(() => import("../pages/LoadingPage"))
const ProductInfoPage = lazy(() => import("../pages/product_info/ProductInfo"))
const ProductListPage = lazy(() => import("../pages/product_list/ProductList"))
const ProductDetail = lazy(() => import("../pages/product_detail/ProductDetail"))
const ProductEdit = lazy(() => import("../pages/product_edit/ProductEdit"))
const root = createBrowserRouter([
    {
        path: "register",
        element: <Suspense fallback={<LoadingPage />}><RegisterPage/></Suspense>
    },
    {
        path: "",
        element: <Suspense fallback={<LoadingPage />}><LoginPage/></Suspense>
    },
    // {
    //     path: "member",
    //     element: <Suspense fallback={Loading}><MemberList/></Suspense>,
    //     children: memberRouter()
    // },
    {
        path: "productInfo",
        element: <Suspense fallback={<LoadingPage />}><ProductInfoPage/></Suspense>
    },
    {
        path: "productList",
        element: <Suspense fallback={<LoadingPage />}><ProductListPage/></Suspense>
    },
    {
        path: "productDetail/:id",
        element: <Suspense fallback={<LoadingPage />}><ProductDetail/></Suspense>
    },
    {
        path: "ProductEdit/:id",
        element: <Suspense fallback={<LoadingPage />}><ProductEdit/></Suspense>
    },
    {
        path: "member",
        element: <Suspense fallback={<LoadingPage />}><MemberList/></Suspense>
    },
    {
        path: "member/withdraw",
        element: <Suspense fallback={<LoadingPage />}><WithdrawMemberList/></Suspense>
    },
    {
        path: "member/:mid",
        element: <Suspense fallback={<LoadingPage />}><MemberDetail/></Suspense>
    },
    {
        path: "member/stats",
        element: <Suspense fallback={<LoadingPage />}><MemberStatsPage/></Suspense>
    },
    {
        path: "trade",
        element: <Suspense fallback={<LoadingPage />}><OnProgressedTradePermissionList/></Suspense>
    },
    {
        path: "trade/all",
        element: <Suspense fallback={<LoadingPage />}><TradePermissionList/></Suspense>
    },
    {
        path: "certificate",
        element: <Suspense fallback={<LoadingPage />}><CertificationMarkList/></Suspense>
    },
    {
        path: "certificate/progressed",
        element: <Suspense fallback={<LoadingPage />}><CertificationMarkProgressedList/></Suspense>
    },
    {
        path: "certificate/:mid",
        element: <Suspense fallback={<LoadingPage />}><CertificationMarkDetail/></Suspense>
    },
    {
        path: "admin",
        element: <Suspense fallback={<LoadingPage />}><AdminListPage/></Suspense>
    },
    {
        path: "edit-member",
        element: <Suspense fallback={<LoadingPage />}><EditMember/></Suspense>
    },
    {
        path: "edit-certification-mark",
        element: <Suspense fallback={<LoadingPage />}><EditCertificationMark/></Suspense>
    },
    {
        path: "juso-popup",
        element: <Suspense fallback={<LoadingPage />}><JusoPopup/></Suspense>
    },
    {
        path: "payment",
        element: <Suspense fallback={<LoadingPage />}><TradeList/></Suspense>
    },
    {
        path: "payment/:pid",
        element: <Suspense fallback={<LoadingPage />}><TradeDetailPage/></Suspense>
    },
    {
        path: "payment/refund",
        element: <Suspense fallback={<LoadingPage />}><RefundList/></Suspense>
    },
    {
        path: "payment/refund/:rid",
        element: <Suspense fallback={<LoadingPage />}><RefundDetailPage/></Suspense>
    },
    {
        path: "payment/stats",
        element: <Suspense fallback={<LoadingPage />}><TradeStatsPage/></Suspense>
    },
    {
        path: "business",
        element: <Suspense fallback={<LoadingPage />}><AuctionsList/></Suspense>
    },
    {
        path: "business/progressed",
        element: <Suspense fallback={<LoadingPage />}><AuctionProgressedList/></Suspense>
    },
    {
        path: "business/:aid",
        element: <Suspense fallback={<LoadingPage />}><AuctionDetailPage/></Suspense>
    },
    {
        path: "business/progressed/:aid",
        element: <Suspense fallback={<LoadingPage />}><AuctionDetailPage/></Suspense>
    },
    {
        path: "business/progressed/sales",
        element: <Suspense fallback={<LoadingPage />}><SaleProgressedList/></Suspense>
    },
    {
        path: "business/sales",
        element: <Suspense fallback={<LoadingPage />}><SalesList/></Suspense>
    },
    {
        path: "business/sales/:sid",
        element: <Suspense fallback={<LoadingPage />}><SaleDetailPage/></Suspense>
    },
    {
        path: "business/progressed/sales/:sid",
        element: <Suspense fallback={<LoadingPage />}><SaleDetailPage/></Suspense>
    },
    {
        path: "business/edit",
        element: <Suspense fallback={<LoadingPage />}><AuctionEditPage/></Suspense>
    },
    {
        path: "business/sales/edit",
        element: <Suspense fallback={<LoadingPage />}><SaleEditPage/></Suspense>
    },
    {
        path: "product",
        element: <Suspense fallback={<LoadingPage />}><ProductList/></Suspense>
    },
    {
        path: "product/:pid",
        element: <Suspense fallback={<LoadingPage />}><ProductDetailPage/></Suspense>
    },
    {
        path: "product/edit",
        element: <Suspense fallback={<LoadingPage />}><ProductEditPage/></Suspense>
    },
    {
        path: "cs/inquiry",
        element: <Suspense fallback={<LoadingPage />}><InquiryList/></Suspense>
    },{
        path: "cs/inquiry/:id",
        element: <Suspense fallback={<LoadingPage />}><InquiryDetailPage/></Suspense>
    },
    {
        path: "cs/report",
        element: <Suspense fallback={<LoadingPage />}><ReportList/></Suspense>
    },
    {
        path: "cs/report/:rid",
        element: <Suspense fallback={<LoadingPage />}><ReportDetailPage/></Suspense>
    },
    {
        path: "error",
        element: <Suspense fallback={<LoadingPage />}><ErrorPage/></Suspense>
    },
    {
        path: "auction_detail/*",
        element: <Suspense fallback={<LoadingPage />}><LinkPage/></Suspense>
    },
    {
        path: "sale_detail/*",
        element: <Suspense fallback={<LoadingPage />}><LinkPage/></Suspense>
    },
])

export default root;