import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import logo from '../../images/LogoText.png'

function LinkPage(props) {
    const location = useLocation()
    const navigator = useNavigate()
    const { message, code } = location.state || {}
    const [cookies, removeCookie] = useCookies()
    const timerRef = useRef(null);  // ✅ `useRef`로 타이머 저장
    const [showDownload, setShowDownload] = useState(false); // 다운로드 버튼 표시 여부
    const [loading, setLoading] = useState(false);
   
    useEffect(() => {
        function handler() {
          clearTimeout(timerRef.current);
        }
      
          // (3) window의 visibilitychange 이벤트가 발생하면 앱이 실행됐다고 가정한다. timeout을 제거한다.
        window.addEventListener('visibilitychange', handler);
      }, []);
      
      const launchApp = () => {

        const currentUrl = window.location.href; // 현재 웹 URL 가져오기
        const appUrl = currentUrl.replace('https://www.drmadmin.kr/', 'drmarket://'); // 앱 스킴으로 변경
    
         // (1) 주어진 딥링크로 앱 실행
         window.location.href = appUrl;
    
    
            console.log("launchApp", window.location.href);
          
              // (2) 일정한 시간동안 앱이 실행되지 않으면 특정 기능을 실행하는 timeout을 생성한다
            timerRef.current = setTimeout(() => {
              window.location.href = `http://localhost:8080/download`
            }, 5000);
          };

    

          return (
            <div className="flex flex-col items-center justify-center h-screen bg-white px-6">
                {/* 로고 섹션 */}
                <div className="flex justify-center mb-6">
                    <img src={logo} className=" h-auto" alt="Doctor Market Logo" />
                </div>
    
                {/* 안내 메시지 */}
                <div className="text-center text-blue-500 font-bold text-lg mb-4">
                    의료기기 거래 플랫폼 Doctor Market에서 쉽고 빠르게!
                </div>
    
                <h3 className="text-gray-700 text-md mb-6">닥터장터에서 편리하게 거래를 시작하세요.</h3>
    
                {/* 앱 실행 버튼 */}
                <button
                    className="w-full max-w-xs bg-blue-600 text-white font-semibold py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
                    onClick={launchApp}
                    disabled={loading}
                >
                    {loading ? (
                        <span className="animate-spin h-5 w-5 border-4 border-white border-t-transparent rounded-full"></span>
                    ) : (
                        '앱에서 열기'
                    )}
                </button>
    
                {/* 앱 다운로드 버튼 (앱이 실행되지 않았을 경우) */}
                {showDownload && (
                    <div className="mt-6 text-center">
                        <p className="text-gray-600 text-sm mb-2">앱이 설치되어 있지 않나요?</p>
                        <a
                            href="http://localhost:8080/download"
                            className="w-full max-w-xs bg-gray-800 text-white font-semibold py-3 rounded-lg shadow-md hover:bg-gray-900 transition duration-300 block text-center"
                        >
                            닥터장터 앱 다운로드
                        </a>
                    </div>
                )}
            </div>
        );
    }
    

export default LinkPage;