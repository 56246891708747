import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// 페이지 이동(navigate) 관련 처리를 진행하는 컴포넌트
const useCustomMove = () => {
    const navigate = useNavigate()

    const [adminRefresh, setAdminRefresh] = useState(false)
    const [onProgressedTradePermissionRefresh, setOnProgressedTradePermissionRefresh] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [progressedCrtfRefresh, setProgressedCrtfRefresh] = useState(false)
    const [crtfRefresh, setCrtfRefresh] = useState(false)
    const [memberRefresh, setMemberRefresh] = useState(false)
    const [withdrawMemberRefresh, setWithdrawMemberRefresh] = useState(false)
    const [memberStatsRefresh, setMemberStatsRefresh] = useState(false)
    const [progressedAuctionRefresh, setProgressedAuctionRefresh] = useState(false)
    const [auctionRefresh, setAuctionRefresh] = useState(false)
    const [progressedSaleRefresh, setProgressedSaleRefresh] = useState(false)
    const [saleRefresh, setSaleRefresh] = useState(false)
    const [productRefresh, setProductRefresh] = useState(false)
    const [inquiryRefresh, setInquiryRefresh] = useState(false)
    const [paymentRefresh, setPaymentRefresh] = useState(false)
    const [refundRefresh, setRefundRefresh] = useState(false)
    const [statsRefresh, setStatsRefresh] = useState(false)
    const [reportRefresh, setReportRefresh] = useState(false)

    const moveToLoginPage = () => {
        navigate({pathname: "/"})
    }

    const moveToRegisterPage = () => {
        navigate({pathname: '/register'})
    }

    const moveToAdminPage = () => {
        setAdminRefresh(!adminRefresh)
        navigate({pathname: '../admin'})
    }

    const moveToOnProgressedTradePermissionList = () => {
        setOnProgressedTradePermissionRefresh(!onProgressedTradePermissionRefresh)
        // console.log(onProgressedTradePermissionRefresh)

        navigate({pathname: '../trade'})
    }

    const moveToTradePermissionList = () => {
        // console.log(refresh)
        // setRefresh(!refresh)
        setRefresh(!refresh)
        // console.log(refresh)

        navigate({pathname: '../trade/all'})
    }

    const moveToProgressedCrtfList = () => {
        setProgressedCrtfRefresh(!progressedCrtfRefresh)
        navigate({pathname: '../certificate/progressed'})
    }

    const moveToCrtfList = () => {
        setCrtfRefresh(!crtfRefresh)

        navigate({pathname: '../certificate'})
    }

    const moveToCrtfDetail = (mid) => {
        navigate({pathname: `../certificate/${mid}`})
    }

    const moveToMemberList = () => {
        // setMemberRefresh(!memberRefresh)
        setMemberRefresh(prev => !prev)
        navigate({pathname: '../member'})
    }

    const moveToWithdrawMemberList = () => {
        // setMemberRefresh(!memberRefresh)
        setWithdrawMemberRefresh(prev => !prev)
        navigate({pathname: '../member/withdraw'})
    }

    const moveToMemberDetail = (mid) => {
        navigate({pathname: `../member/${mid}`})
    }

    const moveToMemberStats = () => {
        setMemberStatsRefresh(prev => !prev)
        navigate({pathname: '../member/stats'})
    }

    const moveToPaymentList = () => {
        setPaymentRefresh(!paymentRefresh)
        navigate({pathname: '../payment'})
    }

    const moveToPaymentDetail = (pid, backup) => {
        navigate({
            pathname: `../payment/${pid}`,
            search: `?backup=${backup}`
        })
    }

    const moveToRefundList = () => {
        setRefundRefresh(!refundRefresh)
        navigate({pathname: '../payment/refund'})
    }

    const moveToRefundDetail = (rid, backup) => {
        navigate({
            pathname: `../payment/refund/${rid}`,
            search: `?backup=${backup}`
        })
    }

    const moveToPaymentStats = () => {
        setStatsRefresh(!statsRefresh)
        navigate({pathname:'../payment/stats'})
    }

    const moveToAuctionsList = () => {
        setAuctionRefresh(!auctionRefresh)
        navigate({pathname: '../business'})
    }

    const moveToProgressedAuctionList = () => {
        setProgressedAuctionRefresh(!progressedAuctionRefresh)
        navigate({pathname:'../business/progressed'})
    }

    const moveToAuctionDetail = (aid) => {
        navigate({pathname: `../business/${aid}`})
    }

    const moveToProgressedAuctionDetail = (aid) => {
        navigate({pathname: `../business/progressed/${aid}`})
    }

    const moveToProgressedSalesList = () => {
        setProgressedSaleRefresh(!progressedSaleRefresh)
        navigate({pathname: '../business/progressed/sales'})
    }

    const moveToSalesList = () => {
        setSaleRefresh(!saleRefresh)
        navigate({pathname: '../business/sales'})
    }

    const moveToProgressedSaleDetail = (sid) => {
        navigate({pathname: `../business/progressed/sales/${sid}`})
    }

    const moveToSaleDetail = (sid) => {
        navigate({pathname: `../business/sales/${sid}`})
    }

    const moveToProductList = () => {
        setProductRefresh(!productRefresh)
        navigate({pathname: '../product'})
    }

    const moveToProductDetail = (pid) => {
        navigate({pathname: `../product/${pid}`})
    }

    const moveToInquiryList = () => {
        setInquiryRefresh(!inquiryRefresh)
        navigate({pathname: '../cs/inquiry'})
    }

    const moveToInquiryDetail = (id) => {
        navigate({pathname: `../cs/inquiry/${id}`})
    }

    const moveToReportList = () => {
        setReportRefresh(!reportRefresh)
        navigate({pathname: '../cs/report'})
    }

    const moveToReportDetail = (rid) => {
        navigate({pathname: `../cs/report/${rid}`})
    }

    const moveToProductInfoPage = () => {
        // setStatsRefresh(!statsRefresh)
        navigate({pathname:'../productInfo'})
    } 
    const moveToProductListPage = () => {
        // setStatsRefresh(!statsRefresh)
        navigate({pathname:'../productList'})
    } 
    const moveToProductDetailPage = (pid) => {
        // setStatsRefresh(!statsRefresh)
        navigate({pathname:`../productDetail/${pid}`})
    } 
    const moveToProductEditPage = (pid) => {
        // setStatsRefresh(!statsRefresh)
        navigate({pathname:`../productEdit/${pid}`})
    } 


    useEffect(() => {
        console.log("member refresh triggered", memberRefresh)
    }, [memberRefresh])

    return {onProgressedTradePermissionRefresh, adminRefresh, refresh, progressedCrtfRefresh, crtfRefresh, memberRefresh, withdrawMemberRefresh, memberStatsRefresh, paymentRefresh, refundRefresh, statsRefresh, progressedAuctionRefresh, auctionRefresh, progressedSaleRefresh, saleRefresh, productRefresh, inquiryRefresh, reportRefresh, moveToLoginPage, moveToRegisterPage, moveToAdminPage ,moveToOnProgressedTradePermissionList, moveToTradePermissionList, moveToCrtfList, moveToProgressedCrtfList, moveToCrtfDetail, moveToMemberList, moveToWithdrawMemberList, moveToMemberStats, moveToMemberDetail, moveToPaymentList, moveToPaymentDetail, moveToRefundList, moveToRefundDetail, moveToPaymentStats, moveToProgressedAuctionList, moveToAuctionsList, moveToProgressedAuctionDetail, moveToAuctionDetail, moveToProgressedSalesList, moveToSalesList, moveToProgressedSaleDetail, moveToSaleDetail, moveToProductList, moveToProductDetail, moveToInquiryList, moveToInquiryDetail, moveToReportList, moveToReportDetail, moveToProductInfoPage, moveToProductListPage, moveToProductDetailPage, moveToProductEditPage}
}

export default useCustomMove;