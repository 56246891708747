import { ko } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import { updateMark } from '../../api/certificationMarkApi';
import {useTokenManager} from "../../api/useTokenManager";
import {useNavigate} from "react-router-dom";

function EditCertificationMark(props) {
    const query = new URLSearchParams(window.location.search);
    const tokenManager = useTokenManager()
    const navigator = useNavigate()

    const [mark, setMark] = useState({
        mid: query.get('mid') || 0,
        serialNumber: query.get('serialNumber') || '',
        status: query.get('status') || '',
        warranty: query.get('warranty') === 'true' ? '신청' : '거절',
        member: {
            id: query.get('memberId') || 0,
            name: query.get('name') || '',
            memberType: query.get('memberType') || '',
            phoneNumber: query.get('phoneNumber') || '',
            email: query.get('email') || '',
            postCode: query.get('postCode') || '',
            address: query.get('address') || '',
            detailAddress: query.get('detailAddress') || '',
            extraAddress: query.get('extraAddress') || ''
        },
        inspection: {
            id: query.get('inspectionId') || 0,
            inspectionCompany: query.get('inspectionCompany') || '',
            meetingTime: query.get('meetingTime') === 'null' ? new Date() : new Date(query.get('meetingTime')),
        }
    })

    console.log('phoneNumber', mark.member.phoneNumber)
    console.log('mark==',mark)
    console.log('email=', mark.member.email === 'null')

    // input 필드의 onChange 이벤트 핸들러 -> 키보드 입력 하나하나에 반응
    // debounce : 입력 필드에서 빠르게 타이핑하는 동안 상태 업데이트를 제한하고 일정 시간 후에만 상태를 업데이트하도록 할 수 있는 라이브러리
    //  - API 호출 등 비용이 큰 작업에서 유용
    // const debouncedChangeHandler = useCallback(
    //     debounce((name, value) => {
    //         setMark((prevMark) => ({
    //             ...prevMark,
    //             [name]: value,
    //         }))
    //     }, 300),
    //     []
    // )
    // const debouncedChangeHandler = useCallback(
    //     debounce((path, value) => {
    //         setMark((prevMark) => {
    //             const newState = {...prevMark}
    //             let current = newState
    //             const keys = path.split('.')
    //             keys.slice(0, -1).forEach(key => {
    //                 current = current[key]
    //             });
    //             current[keys[keys.length - 1]] = value;
    //             return newState;
    //         })
    //     }, 50), []
    // )

    const handleChange = (path) => (e) => {
        const {value} = e.target
        // debouncedChangeHandler(path, value)
        setMark((prevMark) => {
            const newState = {...prevMark}
            let current = newState
            const keys = path.split('.')
            keys.slice(0, -1).forEach(key => {
                current = current[key]
            });
            current[keys[keys.length - 1]] = value;
            return newState;
        })
    }

    // const handleChangeMark = (e) => {
    //     const { name, value } = e.target;
    //     debouncedChangeHandler(name, value)
    // }

    // const handleChangeMember = (e) => {
    //     const { name, value } = e.target
    //     debouncedChangeHandler(name, value)
    // }

    const handleChangeMeetingTime = (date) => {
        setMark((prevMark) => ({
            ...prevMark,
            inspection: {
                ...prevMark.inspection,
                meetingTime: date,
            }
        }))
    }

    // const handleChangeMember = (e) => {
    //     const { name, value } = e.target;
    //     setMember((prevMember) => ({
    //         ...prevMember,
    //         [name]: value,
    //     }));
    // }

    const handleClickJuso = () => {
        window.open(`/juso-popup`, 'pop', 'width=700,height=510, scrollbars=yes, resizable=yes');
    };

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin === window.location.origin) {
                const addressData = event.data;
                setMark((prevMark) => ({
                    ...prevMark,
                    member: {
                        postCode: addressData.zipNo || mark.member.postCode,
                        address: addressData.roadAddrPart1 || mark.member.address,
                        extraAddress: addressData.roadAddrPart2 || mark.member.extraAddress,
                    }
                }));
                console.log(`${addressData.roadAddrPart1} ${addressData.roadAddrPart2} ${addressData.zipNo}`)
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        // const updatedData = {...mark, ...member}
        const updatedData = {...mark}
        console.log('Updated Data:', updatedData);
        window.opener.postMessage(updatedData, '*'); // 부모 창으로 데이터 전송
        window.close(); // 수정 완료 후 창 닫기
    }

    const handleClickUpdate = () => {
        mark.warranty = mark.warranty === '신청' ? true : false
        mark.inspection.meetingTime = mark.inspection.meetingTime.toISOString()
        console.log('mark:',mark)
        updateMark(mark, tokenManager).then(data => {
            console.log(data)
            // if(data === null) {
            //     // TODO: 예외 처리
            // } else {
            //     window.opener.postMessage({
            //         type: 'updateResult',
            //         result: 'Updated Successfully'
            //     }, window.location.origin)
            //     window.close()
            // }
            window.opener.postMessage({
                type: 'updateResult',
                result: 'Updated Successfully'
            }, window.location.origin)
            window.close()
        }).catch(error => {
            let message = null
            let code = null
            if(error.response.status === 401) {
                message = error.message
                code = error.response.status
            } else if(error.response.data) {
                message = error.response.data.error
                code = error.response.data.status
            } else {
                message = error.message
                code = undefined
            }

            navigator('/error', {
                state: {message, code}
            })
        })
    }

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 w-full max-w-lg">
                <h2 className="text-xl font-bold mb-4">인증마크 정보 수정</h2>
                {/* <form onSubmit={handleSubmit}> */}
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">인증 마크 세부 정보</h3>
                    <label className="block text-gray-700">시리얼 번호</label>
                    <input
                    type="text"
                    name="serialNumber"
                    value={mark.serialNumber}
                    onChange={handleChange('serialNumber')}
                    className="border rounded px-2 py-1 w-full"
                    />
                    <label className="block text-gray-700 mt-2">상태</label>
                    {/* <input
                    type="text"
                    name="markStatus"
                    value={mark.status}
                    onChange={handleChange('status')}
                    className="border rounded px-2 py-1 w-full"
                    /> */}
                    <select
                        name="markStatus"
                        value={mark.status}
                        onChange={handleChange('status')}
                        className="border rounded px-2 py-1 w-full">
                            <option value="결제 중">결제 중</option>
                            <option value="신청 접수 중">신청 접수 중</option>
                            <option value="일정 조율 중">일정 조율 중</option>
                            <option value="점검 중">점검 중</option>
                            <option value="인증마크 부여">인증마크 부여</option>
                            <option value="신청 반려">신청 반려</option>
                            <option value="결제 실패">결제 실패</option>
                            <option value="오류 발생">오류 발생</option>
                    </select>
                    <label className="block text-gray-700 mt-2">워런티 여부</label>
                    {/* <input
                    type="text"
                    name="warranty"
                    value={mark.warranty === 'true' ? '신청' : '거절'}
                    onChange={handleChange('warranty')}
                    className="border rounded px-2 py-1 w-full"
                    /> */}
                    <div className="flex space-x-4">
                            {['신청', '거절'].map(option => (
                                <button
                                    key={option}
                                    type="button"
                                    onClick={() => setMark({ ...mark, warranty: option })}
                                    className={`border rounded px-4 py-2 w-full ${
                                        mark.warranty === option ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
                                    }`}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                </div>
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">신청자 정보</h3>
                    <label className="block text-gray-700">이름</label>
                    <input
                    type="text"
                    name="memberName"
                    value={mark.member.name}
                    onChange={handleChange('member.name')}
                    className="border rounded px-2 py-1 w-full"
                    />
                    <label className="block text-gray-700 mt-2">전화번호</label>
                    <input
                    type="text"
                    name="phoneNumber"
                    value={mark.member.phoneNumber}
                    onChange={handleChange('member.phoneNumber')}
                    className="border rounded px-2 py-1 w-full"
                    />
                    <label className="block text-gray-700 mt-2">회원 유형</label>
                    {/* <input
                    type="text"
                    name="memberType"
                    value={mark.member.memberType}
                    onChange={handleChange('member.memberType')}
                    className="border rounded px-2 py-1 w-full"
                    /> */}
                    <select
                        name="memberType"
                        value={mark.member.memberType}
                        onChange={handleChange('member.memberType')}
                        className="border rounded px-2 py-1 w-full">
                            <option value="기본">기본</option>
                            <option value="일반의">일반의</option>
                            <option value="전문의">전문의</option>
                            <option value="사업자">사업자</option>
                    </select>
                    <label className="block text-gray-700 mt-2">이메일</label>
                    <input
                    type="email"
                    name="email"
                    value={mark.member.email}
                    onChange={handleChange('member.email')}
                    className="border rounded px-2 py-1 w-full"
                    />
                    <div className="mb-4">
                    <label className="block text-gray-700">주소</label>
                    <div className='space-y-2'>
                    <input
                            type="text"
                            name="postCode"
                            value={mark.member.postCode}
                            onChange={handleChange('member.postCode')}
                            className="border rounded px-2 py-1 w-full"
                            placeholder="우편번호"
                            disabled
                        />
                        <div className="flex space-x-2">
                            <input
                                type="text"
                                name="address"
                                value={mark.member.address}
                                onChange={handleChange('member.address')}
                                className="border rounded px-2 py-1 w-full"
                                placeholder="주소"
                                disabled
                            />
                            <input
                                type="text"
                                name="extraAddress"
                                value={mark.member.extraAddress}
                                onChange={handleChange('member.extraAddress')}
                                className="border rounded px-2 py-1 w-1/4"
                                placeholder="참고항목"
                                disabled
                            />
                        </div>
                        <input
                            type="text"
                            name="detailAddress"
                            value={mark.member.detailAddress}
                            onChange={handleChange('member.detailAddress')}
                            className="border rounded px-2 py-1 w-full"
                            placeholder="상세주소"
                        />
                    </div>
                    <button onClick={handleClickJuso} className="bg-blue-500 text-white px-4 py-2 rounded mt-2">
                    {/* <button className="bg-blue-500 text-white px-4 py-2 rounded mt-2"> */}
                        주소 변경
                    </button>
                </div>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">검사 정보</h3>
                    <label className="block text-gray-700">검사 업체</label>
                    {/* <input
                    type="text"
                    name="company"
                    value={mark.inspection.inspectionCompany}
                    onChange={handleChange('inspection.inspectionCompany')}
                    className="border rounded px-2 py-1 w-full"
                    /> */}
                    <select
                        name="company"
                        value={mark.inspection.inspectionCompany}
                        onChange={handleChange('inspection.inspectionCompany')}
                        className="border rounded px-2 py-1 w-full">
                            <option value="메디맨">메디맨</option>
                            <option value="메디코">메디코</option>
                    </select>
                    <label className="block text-gray-700 mt-2">만남 시간</label>
                    <DatePicker
                    selected={mark.inspection.meetingTime}
                    onChange={handleChangeMeetingTime}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="yyyy.MM.dd HH:mm"
                    locale={ko}
                    className='border rounded px-2 py-1 w-full'
                    popperClassName="react-datepicker-popper"
                    calendarClassName="react-datepicker-calendar"
                    />
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                    type="button"
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                    onClick={() => window.close()}
                    >
                    취소
                    </button>
                    <button
                    type="button"
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={handleClickUpdate}
                    >
                    저장
                    </button>
                </div>
                {/* </form> */}
            </div>
        </div>
    );
}

export default EditCertificationMark;