import axios from "axios";
import {withTokenReissue} from "./withTokenReissue";

const prefix = `${process.env.REACT_APP_API_SERVER_HOST}/admin/users`

// 회원 전체 조회
export const getList = async (searchTerm, searchBy, filterStatus, direction, page, size,memberType, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.get(`${prefix}`, {
                params: {
                    searchTerm: searchTerm,
                    searchBy: searchBy,
                    filterStatus: filterStatus,
                    direction: direction,
                    page: page,
                    size: size,
                    memberType:memberType
                },
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `${token}`
                },
                withCredentials: true
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('member getList error:', error)
        throw error
    }
}

// 탈퇴 요청 회원 전체 조회
export const getWithdrawList = async (searchTerm, searchBy, direction, page, size, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.get(`${prefix}/withdraw`, {
                params: {
                    searchTerm: searchTerm,
                    searchBy: searchBy,
                    direction: direction,
                    page: page,
                    size: size
                },
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('withdraw member getList error:', error)
        throw error
    }
}

// 회원 상세 조회
export const getOne = async (mid, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.get(`${prefix}/${mid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('get member error:', error)
        throw error
    }
}

// 회원 탈퇴 요청
export const deleteMember = async (mid, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.delete(`${prefix}/${mid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('delete member error:', error)
        throw error
    }
}

// 회원 정보 업데이트
export const updateMember = async (updateObj, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.patch(`${prefix}`, updateObj, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('update member error:', error)
        throw error
    }
}

// 회원 탈퇴 처리
export const approveDelete = async (mid, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.delete(`${prefix}/approve/${mid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('approve delete error:', error)
        throw error
    }
}

// 회원 탈퇴 반려
export const rejectDelete = async (mid, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.patch(`${prefix}/reject/${mid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch (error) {
        console.log('reject delete error:', error)
        throw error
    }
}

// 회원 통계
export const getMemberStatistics = async (query, start, end, tokenManager) => {
    const { accessToken, refreshToken, handleReissueToken, handleExpiredRefresh } = tokenManager
    try {
        return withTokenReissue(
            (token) => axios.get(`${prefix}/statistics`, {
                params: {
                    query: query,
                    startDate: start,
                    endDate: end
                },
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `${token}`
                }
            }),
            accessToken, refreshToken, handleReissueToken, handleExpiredRefresh
        )
    } catch(error) {
        console.log('member statistics error:', error)
        throw error
    }
}